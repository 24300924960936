<template>
  <div class="placeholder">
    <div class="placeholderBox">
      <img
        class="placeholderImg"
        src="~assets/img/drawableinfo/icon_placeholder.png"
      />
      <div class="placeholderText">{{ $t.noMoreData }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Placeholder",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.placeholder {
  width: 100%;
  .placeholderBox {
    width: 155px;
    position: relative;
    margin: 0 auto;
    .placeholderImg {
      width: 100%;
      object-fit: cover;
      -webkit-object-fit: cover;
    }
    .placeholderText {
      text-align: center;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
